<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    modelValue?: boolean
    title?: string
    message?: string
    buttonText?: string
    icon?: string
    target?: string
  }>(),
  {
    modelValue: false,
    title: '',
    message: '',
    buttonText: 'OK',
    icon: 'mdi-information',
  },
)

const emit = defineEmits(['update:modelValue'])

const isVisible = computed({
  get() {
    return props.modelValue
  },
  set(v) {
    emit('update:modelValue', v)
  },
})

async function close() {
  if (props.target) await navigateTo(props.target)
  isVisible.value = false
}
</script>

<template>
  <VDialog v-model="isVisible" persistent max-width="600">
    <VCard>
      <VToolbar
        class="d-flex align-center bg-primary pt-1 pb-1"
        style="font-size: 1.2em; font-weight: 500"
      >
        <VIcon :icon="props.icon" class="ml-3 mr-2" />
        {{ props.title }}
        <VSpacer />
        <VBtn icon color="text" @click="close">
          <VIcon icon="mdi-close" />
        </VBtn>
      </VToolbar>
      <VCardText>
        <slot />
        <slot name="beforeMessage" />
        {{ props.message }}
        <slot name="afterMessage" />
      </VCardText>
      <VCardActions class="align-center justify-center gap-2">
        <VBtn variant="elevated" size="large" @click="close">
          {{ props.buttonText }}
        </VBtn>
      </VCardActions>
    </VCard>
  </VDialog>
</template>
